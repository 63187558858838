.obisconcept-bootstrap-button {
    margin-right: 10px;
    display: inline-block;
}

.obisconcept-bootstrap-button.pull-right {
    margin-right: 0px;
    margin-left: 10px;
}

.obisconcept-bootstrap-button .btn {
    line-height: 14px;
}
.nav-tabs {
  margin-bottom: 15px;
}

.nav-tabs.nav-stacked {
  padding-right: 0;
  border-bottom-color: transparent;

  > li {
    > a {
      margin-right: -1px;
      border-right-color: transparent;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 4px;

      &:hover {
        border-color: #eee #ddd #eee #eee;
      }
    }

    &.active > a {
      border-bottom-color: #ddd;

      &:hover {
        border-color: #eee #ddd #eee #eee;
      }
    }
  }

  + .tab-collection {
    border-left: 1px solid #ddd;
  }
}
